

/*=====================================
        About Section Start Here
=====================================*/

.about-main{
    padding-top: 150px;
    @include breakpoint($secreen-max-md){
        padding-top: 96px;
    }
    @include breakpoint($secreen-xs){
        padding-top: 48px;
    }
    .container{
        .row-custom{
            align-items: center;
            justify-content: space-between;
            @include breakpoint($secreen-max-md){
                gap: 72px 0;
            }
            @include breakpoint($secreen-xs){
                gap: 48px 0;
            }
            .col-content-otr{
                .col-content-inr{
                    .heading{
                        color: $Black-color;
                    }
                    .desc{
                        color: $Black-color-65;
                        &:not(:last-child){
                            margin: 24px 0 16px 0;
                            @include breakpoint($secreen-xs){
                                margin: 16px 0 8px 0;
                            }
                        }
                    }
                    .statisics-otr{
                        display: flex;
                        align-items: center;
                        gap: 0 30px;
                        margin-top: 24px;
                        .content{
                            .box-heading{
                                color: $Primary-color;
                                margin-bottom: 4px;
                            }
                            .box-desc{
                                color: $Black-color-65;
                            }
                        }
                    }
                }
            }
            .col-img-otr{
                .col-img-inr{
                    &:hover{
                        .text-otr{
                            background-color: $Dark-Background;
                        }
                    }
                    .img{
                        width: 100%;
                        height: 350px;
                        object-fit: cover;
                    }
                    .text-otr{
                        background-color: $Primary-color;
                        padding: 16px 72px 16px 36px;
                        transition: .3s;
                        @include breakpoint($secreen-xs){
                            padding: 16px 24px;
                        }
                        .text-heading{
                            font-size: 32px;
                            line-height: 48px;
                            color: $White-color;
                            transition: .3s;
                            @include breakpoint($secreen-xs){
                                font-size: 24px;
                                line-height: 40px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
        About Section End Here
=====================================*/

/*=====================================
        Team Section Start Here
=====================================*/

.team-main{
    margin-top: 130px;
    @include breakpoint($secreen-max-md){
        margin-top: 72px;
    }
    @include breakpoint($secreen-xs){
        margin-top: 48px;
    }
    .container{
        .wrapper{
            text-align: center;
            margin-bottom: 72px;
            @include breakpoint($secreen-xs){
                margin-bottom: 48px;
            }
            .heading{
                color: $Black-color;
                margin-bottom: 24px;
            }
            .desc{
                color: $Black-color-65;
            }
        }
        .row-custom{
            gap: 24px 0;
            justify-content: center;
            .col-team-otr{
                .col-team-inr{
                    position: relative;
                    .img-otr{
                        .img{
                            height: 370px;
                            width: 100%;
                            object-fit: cover;
                            box-shadow: $Box-shadow;
                            @include breakpoint($secreen-xs){
                                height: 450px;
                            }
                        }
                    }
                    .content{
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background-color: $White-color;
                        padding: 16px 24px;
                        text-align: center;
                        width: 100%;
                        .user-name{
                            color: $Black-color;
                            transition: .3s;
                            &:hover{
                                color: $Primary-color;
                            }
                        }
                        .user-post{
                            color: $Black-color-65;
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
        Team Section End Here
=====================================*/