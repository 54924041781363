.body-visible{
    overflow: visible !important;
}

.canvas-display{
    display: none;
}





/*=====================================
        Navbar Start Here
=====================================*/

.navbar-main{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid $White-color-15;
    overflow: hidden;
    z-index: 998;
    transition: .5s;
    .container{
        .wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .logo-otr{
                .logo-inr{
                    padding: 19px 0;
                    .logo{
                        width: 200px;
                        height: 40px;
                        object-fit: contain;
                        object-position: left;
                    }
                }
            }
            .menu-otr{
                position: absolute;
                right: 0;
                top: 0;
                @include breakpoint($secreen-max-md){
                    display: none;
                }
                .menu-ul{
                    display: flex;
                    align-items: center;
                    .menu-li{
                        &:last-child{
                            .menu-a{
                                color: $White-color;
                                background-color: transparent;
                                border-left: 1px solid $White-color-15;
                                &::after{
                                    display: none;
                                }
                                &:hover{
                                    background-color: $Primary-color;
                                    color: $White-color;
                                }
                            }
                        }
                        .menu-a{
                            position: relative;
                            padding: 23px 45px;
                            background-color: transparent;
                            color: $White-color-65;
                            transition: .3s;
                            @include breakpoint($secreen-lg){
                                padding: 23px 30px;
                            }
                            &::after{
                                content: '';
                                position: absolute;
                                width: 16px;
                                height: 16px;
                                background-color: $Primary-color;
                                border-radius: 100%;
                                top: -16px;
                                left: 50%;
                                transform: translateX(-50%);
                                transition: .3s;
                                opacity: 0;
                                visibility: hidden;
                            }
                            &:hover{
                                color: $White-color;
                                &::after{
                                    opacity: 1;
                                    visibility: visible;
                                    top: -8px;
                                }
                            }
                        }
                    }
                }
            }
            .burger-icon-otr{
                display: none;
                align-items: center;
                justify-content: center;
                width: 52px;
                height: 52px;
                background-color: $Primary-light;
                border-radius: 100%;
                transition: .3s;
                @include breakpoint($secreen-max-md){
                    display: flex;
                }
                &:hover{
                    background-color: $Primary-color;
                    .icon{
                        color: $White-color;
                    }
                }
                .icon{
                    font-size: 24px;
                    color: $Primary-color;
                    transition: .3s;
                }
            }
        }
    }
}
.top-header-fixed{
    background-color: $Dark-Background;
}

/*=====================================
        Navbar End Here
=====================================*/

/*=====================================
        Inner header Start Here
=====================================*/

.inner-header{
    position: relative;
    padding: 152px 0 72px 0;
    background-image: url("/assets/img/hero-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    background-color: $Dark-Background;
    @include breakpoint($secreen-xs){
        padding: 128px 0 48px 0;
    }
    &::after{
        content: '';
        position: absolute;
        width: 500px;
        height: 500px;
        background-color: $White-color;
        top: -250px;
        left: -250px;
        filter: blur(300px);
        opacity: 25%;
    }
    &::before{
        content: '';
        position: absolute;
        width: 500px;
        height: 500px;
        background-color: $Primary-color;
        bottom: -250px;
        right: -250px;
        filter: blur(300px);
        opacity: 50%;
    }
    .container{
        .wrapper{
            position: relative;
            text-align: center;
            z-index: 2;
            .heading{
                color: $White-color;
            }
        }
    }
}

/*=====================================
        Inner header End Here
=====================================*/