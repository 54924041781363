

.loader {
    width: 100%;
    height: 100vh;
    background-color: $Dark-Background;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1060;
}
  
.loader .loader-inner {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    animation: Loader 3s ease infinite;
}

@keyframes Loader {
    0% {
        background-color: $Primary-color;
        transform: scale(1);
    }
  
    25% {
        background-color: $Primary-light;
    }
  
    50% {
        background-color: $Primary-color;
    }
  
    75% {
        background-color: $Primary-light;
    }
  
    100% {
        background-color: $Primary-color;
    }
}

/*=====================================
        Overlay Start Here
=====================================*/

.overlay-main{
    .offcanvas-start{
        .offcanvas-header{
            border-bottom: 1px solid $Border-color;
            padding: 16px 24px;
            .logo-inr{
                .logo{
                    width: 200px;
                    height: 40px;
                    object-fit: contain;
                    object-position: left;
                }
            }
            .close-icon-otr{
                display: none;
                align-items: center;
                justify-content: center;
                width: 52px;
                height: 52px;
                background-color: $Primary-light;
                border-radius: 100%;
                transition: .3s;
                @include breakpoint($secreen-max-md){
                    display: flex;
                }
                &:hover{
                    background-color: $Primary-color;
                    .icon{
                        color: $White-color;
                    }
                }
                .icon{
                    font-size: 24px;
                    color: $Primary-color;
                    transition: .3s;
                }
            }
        }
        .offcanvas-body{
            padding: 24px;
            .menu-otr{
                margin-bottom: 36px;
                .menu-ul{
                    .menu-li{
                        &:not(:last-child){
                            margin-bottom: 4px;
                        }
                        .menu-a{
                            color: $Black-color-65;
                            transition: .3s;
                            &:hover{
                                color: $Primary-color;
                            }
                        }
                    }
                }
            }
            .action{
                display: flex;
                width: 100%;
                .contact-btn{
                    width: 100%;
                }
            }
        }
    }
}

/*=====================================
        Overlay End Here
=====================================*/


.nav-hero-about-recentwork{

    /*=====================================
            Hero Section Start Here
    =====================================*/

        .hero-main{
            position: relative;
            padding: 176px 0 128px 0;
            background-image: url("/assets/img/hero-bg.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            overflow: hidden;
            background-color: $Dark-Background;
            @include breakpoint($secreen-max-md){
                padding: 152px 0 72px 0;
            }
            @include breakpoint($secreen-xs){
                padding: 128px 0 48px 0;
            }
            &::after{
                content: '';
                position: absolute;
                width: 1000px;
                height: 1000px;
                background-color: $White-color;
                top: -500px;
                left: -500px;
                filter: blur(300px);
                opacity: 25%;
            }
            &::before{
                content: '';
                position: absolute;
                width: 1000px;
                height: 1000px;
                background-color: $Primary-color;
                bottom: -500px;
                right: -500px;
                filter: blur(300px);
                opacity: 50%;
            }
            .container{
                .row-custom{
                    position: relative;
                    align-items: center;
                    z-index: 2;
                    @include breakpoint($secreen-max-md){
                        gap: 48px 0;
                    }
                    .col-content-otr{
                        .col-content-inr{
                            padding-right: 74px;
                            @include breakpoint($secreen-max-lg){
                                padding-right: 0;
                            }
                            .heading{
                                color: $White-color;
                                @include breakpoint($secreen-lg){
                                    font-size: $Font-size-h2;
                                    line-height: $line-height-h2;
                                }
                            }
                            .desc{
                                color: $White-color-65;
                                margin: 24px 0 44px 0;
                                @include breakpoint($secreen-xxs){
                                    margin: 16px 0 36px 0;
                                }
                            }
                            .action{
                                display: flex;
                            }
                            .statisics-otr{
                                display: inline-flex;
                                align-items: center;
                                background-color: $White-color-5;
                                margin-top: 72px;
                                @include breakpoint($secreen-xs){
                                    margin-top: 48px;
                                }
                                .content{
                                    padding: 24px 36px 28px 36px;
                                    @include breakpoint($secreen-xs){
                                        padding: 16px 20px 16px 20px;
                                    }
                                    &:not(:last-child){
                                        border-right: 1px solid $White-color-15;
                                    }
                                    .box-heading{
                                        color: $White-color;
                                        margin-bottom: 4px;
                                    }
                                    .box-desc{
                                        color: $White-color-65;
                                    }
                                }
                            }
                        }
                    }
                    .col-img-otr{
                        .col-img-inr{
                            .img{
                                height: 720px;
                                width: 100%;
                                object-fit: contain;
                                animation: transition 5s infinite linear;
                                @include breakpoint($secreen-xs){
                                    height: 500px;
                                }
                            }
                        }
                    }
                }
            }
        }

    /*=====================================
            Hero Section End Here
    =====================================*/
}

/*=====================================
    Recent work Section Start Here
=====================================*/

.recent-work-main{
    padding: 130px 0 116px 0;
    @include breakpoint($secreen-max-md){
        padding: 72px 0;
    }
    @include breakpoint($secreen-xs){
        padding: 48px 0;
    }
    .container{
        .wrapper{
            text-align: center;
            margin-bottom: 72px;
            @include breakpoint($secreen-xs){
                margin-bottom: 48px;
            }
            .heading{
                color: $Black-color;
                margin-bottom: 24px;
            }
            .desc{
                color: $Black-color-65;
            }
        }
        .row-custom{
            align-items: center;
            gap: 24px 0;
            .col-box-otr{
                .col-box-inr{
                    background-color: $White-color;
                    box-shadow: $Box-shadow;
                    .img-otr{
                        .img{
                            width: 100%;
                            height: 250px;
                            object-fit: cover;
                        }
                    }
                    .content{
                        padding: 20px 28px 32px 28px;
                        .box-heading{
                            color: $Black-color;
                            margin-bottom: 20px;
                            transition: .3s;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            &:hover{
                                color: $Primary-color;
                            }
                        }
                        .action{
                            display: flex;
                        }
                    }
                }
            }
        }
        .action-otr{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 72px;
            @include breakpoint($secreen-xs){
                margin-top: 48px;
            }
            .see-text{
                color: $Primary-color;
                transition: .3s;
                &:hover{
                    color: $Black-color;
                }
            }
        }
    }
}

/*=====================================
    Recent work Section End Here
=====================================*/

/*=====================================
    Services Section Start Here
=====================================*/

.services-main{
    position: relative;
    overflow: hidden;
    background-image: url(/assets/img/hero-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $Dark-Background;
    padding: 130px 0;
    margin-bottom: 72px;
    @include breakpoint($secreen-max-md){
        padding: 72px 0;
    }
    @include breakpoint($secreen-xs){
        padding: 48px 0;
        margin-bottom: 48px;
    }
    &::after{
        content: '';
        position: absolute;
        width: 1000px;
        height: 1000px;
        background-color: $White-color;
        top: -500px;
        left: -500px;
        filter: blur(300px);
        opacity: 25%;
    }
    &::before{
        content: '';
        position: absolute;
        width: 1000px;
        height: 1000px;
        background-color: $Primary-color;
        bottom: -500px;
        right: -500px;
        filter: blur(300px);
        opacity: 50%;
    }
    .container{
        .wrapper{
            position: relative;
            text-align: center;
            margin-bottom: 72px;
            z-index: 2;
            @include breakpoint($secreen-xs){
                margin-bottom: 48px;
            }
            .heading{
                color: $White-color;
                margin-bottom: 24px;
            }
            .desc{
                color: $White-color-65;
            }
        }
        .row-custom{
            position: relative;
            gap: 24px 0;
            z-index: 2;
            .col-box-otr{
                .col-box-inr{
                    padding: 36px 36px 32px 36px;
                    height: 100%;
                    background-color: $White-color-5;
                    backdrop-filter: blur(50px);
                    @include breakpoint($secreen-xs){
                        padding: 24px 28px;
                    }
                    .icon-otr{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 72px;
                        height: 72px;
                        background-color: $Primary-color;
                        border-radius: 100%;
                    }
                    .content{
                        .box-heading{
                            color: $White-color;
                            margin: 24px 0 16px 0;
                        }
                        .box-desc{
                            color: $White-color-65;
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
    Services Section End Here
=====================================*/

/*=====================================
    Testimonial Section Start Here
=====================================*/

.testimonial-main{
    position: relative;
    overflow: hidden;
    padding-top: 130px;
    margin-bottom: 72px;
    @include breakpoint($secreen-max-md){
        padding-top: 72px;
    }
    @include breakpoint($secreen-xs){
        padding-top: 48px;
        margin-bottom: 48px;
    }
    .container{
        .wrapper{
            text-align: center;
            .heading{
                color: $Black-color;
                margin-bottom: 24px;
            }
            .desc{
                color: $Black-color-65;
            }
        }
        .testimonial-slider{
            padding-top: 72px;
            @include breakpoint($secreen-max-sm){
                padding-bottom: 48px;
            }
            @include breakpoint($secreen-xs){
                padding-top: 48px;
            }
            .swiper-wrapper{
                height: auto;
                .swiper-slide{
                    .col-inr{
                        .content-otr{
                            background-color: $White-color;
                            padding: 32px 36px 26px 36px;
                            box-shadow: $Box-shadow;
                            margin-bottom: 24px;
                            @include breakpoint($secreen-xs){
                                padding: 24px 28px;
                            }
                            .star-ul{
                                display: flex;
                                align-items: center;
                                gap: 0 8px;
                                margin-bottom: 16px;
                                .star-li{
                                    &:last-child{
                                        .star-icon{
                                            color: $Dark-color-15;
                                        }
                                    }
                                    .star-icon{
                                        font-size: 20px;
                                        color: $Primary-color;
                                    }
                                }
                            }
                            .comment{
                                color: $Dark-Background;
                            }
                        }
                        .profile-otr{
                            display: flex;
                            align-items: center;
                            gap: 0 20px;
                            .img-otr{
                                .img{
                                    width: 52px;
                                    height: 52px;
                                    border-radius: 100%;
                                    object-fit: cover;
                                }
                            }
                            .content{
                                .user-name{
                                    color: $Black-color-65;
                                    transition: .3s;
                                    &:hover{
                                        color: $Primary-color;
                                    }
                                }
                                .user-post{
                                    color: $Black-color-40;
                                }
                            }
                        }
                    }
                }
            }
            .swiper-pagination{
                display: none;
                position: absolute;
                bottom: 0;
                @include breakpoint($secreen-max-sm){
                    display: block;
                }
                .swiper-pagination-bullet{
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    background-color: $Black-color-65;
                }
                .swiper-pagination-bullet-active{
                    background-color: $Primary-color;
                }
            }
        }
    }
    .arrow-otr{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        bottom: 45%;
        left: 0;
        width: 100%;
        @include breakpoint($secreen-max-sm){
            display: none;
        }
        .icon-otr{
            position: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 52px;
            height: 52px;
            border-radius: 100%;
            background-color: $Dark-color-15;
            transition: .3s;
            &::after{
                display: none;
            }
            &:hover{
                background-color: $Primary-color;
                .arrow-icon{
                    color: $White-color;
                }
            }
            .arrow-icon{
                font-size: 24px;
                color: $Black-color-65;
                transition: .3s;
            }
        }
    }
}

/*=====================================
    Testimonial Section End Here
=====================================*/

/*=====================================
        Cta Section Start Here
=====================================*/

.cta-main{
    .container{
        .row-custom{
            position: relative;
            padding: 72px 96px;
            align-items: center;
            justify-content: space-between;
            @include breakpoint($secreen-max-md){
                padding: 48px;
                gap: 48px 0;
            }
            @include breakpoint($secreen-xs){
                padding: 48px 16px;
                gap: 24px 0;
            }
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 70%;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                background-color: $Primary-color;
                z-index: -1;
                @include breakpoint($secreen-max-md){
                    height: 100%;
                }
            }
            .col-content-otr{
                .col-content-inr{
                    @include breakpoint($secreen-max-md){
                        margin-bottom: 48px;
                    }
                    .heading{
                        color: $White-color;
                    }
                    .desc{
                        color: $White-color-65;
                        margin: 24px 0 36px 0;
                        @include breakpoint($secreen-xs){
                            margin: 16px 0 24px 0;
                        }
                    }
                    .action{
                        display: flex;
                    }
                }
            }
            .col-img-otr{
                .col-img-inr{
                    .img{
                        height: 570px;
                        width: 100%;
                        object-fit: contain;
                        animation: transition 5s infinite linear;
                        @include breakpoint($secreen-xxs){
                            height: 400px;
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
        Cta Section End Here
=====================================*/

/*=====================================
        Blog Section Start Here
=====================================*/

.blog-main{
    padding: 72px 0 130px 0;
    @include breakpoint($secreen-max-md){
        padding: 72px 0;
    }
    @include breakpoint($secreen-xs){
        padding: 48px 0;
    }
    .container{
        .wrapper{
            text-align: center;
            margin-bottom: 72px;
            @include breakpoint($secreen-xs){
                margin-bottom: 48px;
            }
            .heading{
                color: $Black-color;
                margin-bottom: 24px;
            }
            .desc{
                color: $Black-color-65;
            }
        }
        .row-custom{
            align-items: center;
            gap: 24px 0;
            .col-box-otr{
                .col-box-inr{
                    display: flex;
                    align-items: center;
                    gap: 0 30px;
                    @include breakpoint($secreen-xxs){
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 24px 0;
                    }
                    .img-otr{
                        @include breakpoint($secreen-xxs){
                            width: 100%;
                        }
                        .img{
                            width: 200px;
                            height: 150px;
                            object-fit: cover;
                            @include breakpoint ($secreen-md){
                                width: 150px;
                            }
                            @include breakpoint($secreen-xs){
                                width: 150px;
                            }
                            @include breakpoint($secreen-xxs){
                                width: 100%;
                                height: 200px;
                            }
                        }
                    }
                    .content{
                        .box-tag{
                            color: $Primary-color;
                        }
                        .box-heading{
                            color: $Black-color;
                            margin: 4px 0;
                            transition: .3s;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            &:hover{
                                color: $Primary-color;
                            }
                        }
                        .box-date{
                            color: $Black-color-40;
                        }
                    }
                }
            }
        }
        .action-otr{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 72px;
            @include breakpoint($secreen-xs){
                margin-top: 48px;
            }
            .see-text{
                color: $Primary-color;
                transition: .3s;
                &:hover{
                    color: $Black-color;
                }
            }
        }
    }
}

/*=====================================
        Blog Section End Here
=====================================*/

/*=====================================
        Footer Start Here
=====================================*/

.footer-main{
    background-color: $Dark-Background;
    background-image: url("/assets/img/hero-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    .container{
        .row-custom{
            padding: 130px 0;
            gap: 48px 0;
            @include breakpoint($secreen-max-md){
                padding: 72px 0;
            }
            @include breakpoint($secreen-xs){
                padding: 48px 0;
                gap: 36px 0;
            }
            .col-logo-otr{
                .col-logo-inr{
                    .logo-otr{
                        margin-bottom: 24px;
                        .logo{
                            width: 200px;
                            height: 40px;
                            object-fit: contain;
                            object-position: left;
                        }
                    }
                    .icon-otr{
                        .icon-ul{
                            display: flex;
                            align-items: center;
                            gap: 0 16px;
                            .icon-li{
                                .icon-a{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 52px;
                                    height: 52px;
                                    border-radius: 100%;
                                    background-color: $White-color-10;
                                    transition: .3s;
                                    &:hover{
                                        background-color: $Primary-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .col-navigation-otr{
                .col-navigation-inr{
                    .navigation-heading{
                        color: $White-color;
                        margin-bottom: 8px;
                    }
                    .navigation-ul{
                        .navigation-li{
                            &:not(:last-child){
                                margin-bottom: 6px;
                            }
                            .navigation-a{
                                display: inline;
                                color: $White-color-65;
                                transition: .3s;
                                &:hover{
                                    color: $Primary-color;
                                }
                            }
                        }
                    }
                }
                .navigation2{
                    padding-left: 30px;
                    @include breakpoint($secreen-max-md){
                        padding-left: 0;
                    }
                }
            }
            .col-info-otr{
                .col-info-inr{
                    padding-left: 60px;
                    @include breakpoint($secreen-max-md){
                        padding-left: 0;
                    }
                    .info-heading{
                        color: $White-color;
                        margin-bottom: 12px;
                    }
                    .info-ul{
                        .info-li{
                            &:not(:last-child){
                                margin-bottom: 16px;
                            }
                            .info-a{
                                display: flex;
                                align-items: center;
                                gap: 0 16px;
                                &:hover{
                                    .icon-otr{
                                        background-color: $Primary-color;
                                        .icon{
                                            path{
                                                opacity: 1;
                                            }
                                        }
                                    }
                                    .info-text{
                                        color: $Primary-color;
                                    }
                                }
                                .icon-otr{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 100%;
                                    background-color: $White-color-10;
                                    transition: .3s;
                                    .icon{
                                        transition: .3s;
                                        path{
                                            transition: .3s;
                                        }
                                    }
                                }
                                .info-text{
                                    color: $White-color;
                                    flex: 1;
                                    transition: .3s;
                                    word-break: break-all;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .copyright-main{
        padding: 24px 0;
        border-top: 1px solid $White-color-15;
        .container{
            .wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
                @include breakpoint($secreen-xs){
                    flex-direction: column;
                    gap: 8px 0;
                }
                .copyright-text{
                    color: $White-color-65;
                    .linkk{
                        display: inline;
                        color: $White-color-65;
                        transition: .3s;
                        &:hover{
                            color: $Primary-color;
                        }
                    }
                }
                .linkk-ul{
                    display: flex;
                    align-items: center;
                    gap: 0 30px;
                    .linkk-li{
                        .linkk-a{
                            color: $White-color-65;
                            transition: .3s;
                            &:hover{
                                color: $Primary-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
        Footer End Here
=====================================*/