


/*=====================================
    Services Section Start Here
=====================================*/

.services-main2{
    position: relative;
    overflow: hidden;
    padding: 130px;
    @include breakpoint($secreen-max-md){
        padding: 72px;
    }
    @include breakpoint($secreen-xs){
        padding: 48px;
    }
    .container{
        .wrapper{
            position: relative;
            text-align: center;
            margin-bottom: 72px;
            z-index: 2;
            @include breakpoint($secreen-xs){
                margin-bottom: 48px;
            }
            .heading{
                color: $Black-color;
                margin-bottom: 24px;
            }
            .desc{
                color: $Black-color-65;
            }
        }
        .row-custom{
            position: relative;
            gap: 24px 0;
            z-index: 2;
            .col-box-otr{
                .col-box-inr{
                    padding: 36px 36px 32px 36px;
                    height: 100%;
                    background-color: $Dark-color-5;
                    transition: .3s;
                    @include breakpoint($secreen-xs){
                        padding: 24px 28px;
                    }
                    &:hover{
                        background-color: $White-color;
                        box-shadow: $Box-shadow;
                    }
                    .icon-otr{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 72px;
                        height: 72px;
                        background-color: $Primary-color;
                        border-radius: 100%;
                    }
                    .content{
                        .box-heading{
                            color: $Black-color;
                            margin: 24px 0 16px 0;
                        }
                        .box-desc{
                            color: $Black-color-65;
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
    Services Section End Here
=====================================*/

/*=====================================
        Work Section Start Here
=====================================*/

.recent-work2{
    padding: 0;
    @include breakpoint($secreen-max-md){
        padding: 0;
    }
    @include breakpoint($secreen-xs){
        padding: 0;
    }
}

/*=====================================
        Work Section End Here
=====================================*/