


/*=====================================
        Inner Header Start Here
=====================================*/

.work-detail-header{
    padding: 80px 0 0 0;
    .container{
        .wrapper{
            padding: 72px 0;
            @include breakpoint($secreen-xs){
                padding: 48px 0;
            }
        }
    }
    .work-detail-inr{
        background-color: $White-color-10;
        padding: 16px 0;
        position: relative;
        z-index: 2;
        .container{
            .row-custom{
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 16px 30px;
                @include breakpoint($secreen-max-sm){
                    gap: 16px 0;
                }
                .content-otr{
                    @include breakpoint($secreen-max-sm){
                        width: 50%;
                    }
                    @include breakpoint($secreen-xxs){
                        width: 100%;
                    }
                    .info-text{
                        color: $White-color-65;
                    }
                    .info-name{
                        color: $White-color;
                        transition: .3s;
                        &:hover{
                            color: $Primary-color;
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
        Inner Header End Here
=====================================*/

/*=====================================
        Work Detail Start Here
=====================================*/

.work-detail-main{
    padding: 130px 0;
    @include breakpoint($secreen-max-md){
        padding: 72px 0;
    }
    @include breakpoint($secreen-xs){
        padding: 48px 0;
    }
    .container{
        .row-custom{
            justify-content: center;
            .col-detail-otr{
                .col-detail-inr{
                    .col-box-inr{
                        margin-bottom: 130px;
                        @include breakpoint($secreen-max-md){
                            margin-bottom: 72px;
                        }
                        @include breakpoint($secreen-xs){
                            margin-bottom: 48px;
                        }
                        .content-otr{
                            background-color: $White-color;
                            padding: 32px 36px 26px 36px;
                            box-shadow: $Box-shadow;
                            margin-bottom: 24px;
                            @include breakpoint($secreen-xs){
                                padding: 24px 28px;
                            }
                            .star-ul{
                                display: flex;
                                align-items: center;
                                gap: 0 8px;
                                margin-bottom: 16px;
                                .star-li{
                                    &:last-child{
                                        .star-icon{
                                            color: $Dark-color-15;
                                        }
                                    }
                                    .star-icon{
                                        font-size: 20px;
                                        color: $Primary-color;
                                    }
                                }
                            }
                            .comment{
                                color: $Dark-Background;
                            }
                        }
                        .profile-otr{
                            display: flex;
                            align-items: center;
                            gap: 0 20px;
                            .img-otr{
                                .img{
                                    width: 52px;
                                    height: 52px;
                                    border-radius: 100%;
                                    object-fit: cover;
                                }
                            }
                            .content{
                                .user-name{
                                    color: $Black-color-65;
                                    transition: .3s;
                                    &:hover{
                                        color: $Primary-color;
                                    }
                                }
                                .user-post{
                                    color: $Black-color-40;
                                }
                            }
                        }
                    }
                    .img-otr{
                        .img{
                            height: 470px;
                            width: 100%;
                            object-fit: cover;
                            @include breakpoint($secreen-xs){
                                height: auto;
                            }
                        }
                    }
                    .content-box{
                        margin: 64px 0;
                        @include breakpoint($secreen-xs){
                            margin: 36px 0;
                        }
                        .heading{
                            color: $Black-color;
                        }
                        .heading-small{
                            color: $Black-color;
                            margin: 16px 0 12px 0;
                        }
                        .desc{
                            color: $Black-color-65;
                            &:not(:last-child){
                                margin-bottom: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
        Work Detail End Here
=====================================*/