/*=====================================
        Color Variable's
=====================================*/

$Primary-color: #0E3EDA;
$Primary-light: #E8E8E8;
$Black-color: #000000;
$Black-color-40: rgba(0, 0, 0, 0.40);
$Black-color-65: rgba(0, 0, 0, 0.65);
$White-color: #ffffff;
$White-color-5: rgba(255, 255, 255, 0.05);
$White-color-10: rgba(255, 255, 255, 0.1);
$White-color-15: rgba(255, 255, 255, 0.15);
$White-color-65: rgba(255, 255, 255, 0.65);
$Dark-Background: #1E1E1E;
$Dark-color-15: rgba(30, 30, 30, 0.15);
$Dark-color-5: rgba(30, 30, 30, 0.05);
$Border-color: #D9EFE4;

/*=====================================
        Fonts Variable's
=====================================*/

$Font-size-h1: 56px;
$Font-size-h2: 44px;
$Font-size-h3: 24px;
$Font-size-p1: 20px;
$Font-size-p2: 18px;
$Font-size-p3: 16px;

/*=====================================
        Line-height Variable's
=====================================*/

$line-height-h1: 74px;
$line-height-h2: 64px;
$line-height-h3: 40px;
$line-height-p1: 36px;
$line-height-p2: 34px;
$line-height-p3: 32px;

/*=====================================
        Font-family Variable's
=====================================*/

$font-family-extra-bold: "RedHatDisplay-ExtraBold";
$font-family-medium: "RedHatDisplay-Medium";
$font-family-bold: "RedHatDisplay-Bold";

/*=====================================
        Box Shadow Variable's
=====================================*/


$Box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.1);