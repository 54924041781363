


/*=====================================
        Inner Header Start Here
=====================================*/

.blog-detail-header{
    padding: 80px 0 0 0;
    .container{
        .wrapper{
            padding: 72px 0;
            @include breakpoint($secreen-xs){
                padding: 48px 0;
            }
        }
    }
    .work-detail-inr{
        background-color: $White-color-10;
        padding: 16px 0;
        position: relative;
        z-index: 2;
        .container{
            .row-custom{
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 16px 30px;
                @include breakpoint($secreen-max-sm){
                    gap: 16px 0;
                }
                .content-otr{
                    @include breakpoint($secreen-max-sm){
                        width: 50%;
                    }
                    @include breakpoint($secreen-xxs){
                        width: 100%;
                    }
                    .info-text{
                        color: $White-color-65;
                    }
                    .info-name{
                        color: $White-color;
                        transition: .3s;
                        &:hover{
                            color: $Primary-color;
                        }
                    }
                }
                .social-otr{
                    display: flex;
                    align-items: center;
                    gap: 0 16px;
                    .icon-ul{
                        display: flex;
                        align-items: center;
                        gap: 0 16px;
                        .icon-li{
                            .icon-a{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 52px;
                                height: 52px;
                                border-radius: 100%;
                                background-color: $White-color-10;
                                transition: .3s;
                                &:hover{
                                    background-color: $Primary-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
        Inner Header End Here
=====================================*/

/*=====================================
        blog Detail Start Here
=====================================*/

.blog-detail-main{
    padding: 130px 0;
    @include breakpoint($secreen-max-md){
        padding: 72px 0;
    }
    @include breakpoint($secreen-xs){
        padding: 48px 0;
    }
    .container{
        .row-custom{
            justify-content: center;
            .col-detail-otr{
                .col-detail-inr{
                    padding-bottom: 72px;
                    border-bottom: 1px solid $Dark-color-15;
                    @include breakpoint($secreen-xs){
                        padding-bottom: 48px;
                    }
                    .img-otr{
                        .img{
                            height: 470px;
                            width: 100%;
                            object-fit: cover;
                            @include breakpoint($secreen-xs){
                                height: auto;
                            }
                        }
                    }
                    .content-box{
                        margin: 64px 0;
                        @include breakpoint($secreen-xs){
                            margin: 36px 0;
                        }
                        .heading{
                            color: $Black-color;
                        }
                        .heading-small{
                            color: $Black-color;
                            margin: 16px 0 12px 0;
                        }
                        .desc{
                            color: $Black-color-65;
                            &:not(:last-child){
                                margin-bottom: 12px;
                            }
                        }
                    }
                }
                .col-form-inr{
                    background-color: $White-color;
                    box-shadow: $Box-shadow;
                    padding: 36px;
                    margin: 72px 0 56px 0;
                    @include breakpoint($secreen-xs){
                        margin: 48px 0 36px 0;
                    }
                    .heading{
                        color: $Black-color;
                        margin-bottom: 16px;
                    }
                    .input-otr{
                        .input{
                            width: 100%;
                        }
                        .text-area{
                            appearance: none;
                            resize: none;
                            height: 160px;
                        }
                    }
                    .input-main{
                        display: flex;
                        align-items: center;
                        gap: 0 30px;
                        margin: 30px 0;
                        .input-otr{
                            width: 50%;
                            .input{
                                width: 100%;
                            }
                        }
                    }
                    .action{
                        display: flex;
                        width: 48%;
                        .submit-btn{
                            width: 100%;
                            &:hover{
                                border: 1px solid $Primary-color;
                                color: $Primary-color;
                            }
                        }
                    }
                }
                .col-comment-inr{
                    .box-otr{
                        background-color: $Dark-color-5;
                        padding: 24px 30px;
                        &:not(:last-child){
                            margin-bottom: 30px;
                        }
                        &:nth-child(2){
                            margin-left: 40px;
                            @include breakpoint($secreen-xs){
                                margin-left: 0;
                            }
                        }
                        .profile-main{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 8px;
                            @include breakpoint($secreen-xxs){
                                flex-direction: column;
                                align-items: flex-start;
                                gap: 12px 0;
                                margin-bottom: 16px;
                            }
                            .profile-otr{
                                display: flex;
                                align-items: center;
                                gap: 0 20px;
                                .img-otr{
                                    .img{
                                        width: 52px;
                                        height: 52px;
                                        border-radius: 100%;
                                        object-fit: cover;
                                    }
                                }
                                .content{
                                    .user-name{
                                        color: $Black-color-65;
                                        transition: .3s;
                                        &:hover{
                                            color: $Primary-color;
                                        }
                                    }
                                    .user-post{
                                        color: $Black-color-40;
                                    }
                                }
                            }
                            .action{
                                .reply-btn{
                                    padding: 4px 20px;
                                    background-color: $Primary-light;
                                    color: $Primary-color;
                                    transition: .3s;
                                    &:hover{
                                        background-color: $Primary-color;
                                        color: $White-color;
                                    }
                                }
                            }
                        }
                        .desc{
                            color: $Black-color-65;
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
        blog Detail End Here
=====================================*/