


/*=====================================
        Privacy Policy Start Here
=====================================*/

.privacy-main{
    margin: 130px 0;
    @include breakpoint($secreen-max-md){
        margin: 72px 0;
    }
    @include breakpoint($secreen-xs){
        margin: 48px 0;
    }
    .container{
        .row-custom{
            justify-content: center;
            .col-content-otr{
                .col-content-inr{
                    .heading{
                        color: $Black-color;
                        margin-bottom: 12px;
                    }
                    .desc{
                        color: $Black-color-65;
                        &:not(:last-child){
                            margin-bottom: 12px;
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
        Privacy Policy End Here
=====================================*/