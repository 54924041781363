


/*=====================================
        Contact Section Start Here
=====================================*/

.contact-main{
    padding: 130px 0;
    @include breakpoint($secreen-max-md){
        padding: 72px 0;
    }
    @include breakpoint($secreen-xs){
        padding: 48px 0;
    }
    .container{
        .row-custom{
            align-items: center;
            justify-content: space-between;
            .col-info-otr{
                .col-info-inr{
                    @include breakpoint($secreen-max-md){
                        margin-bottom: 72px;
                    }
                    @include breakpoint($secreen-xs){
                        margin-bottom: 48px;
                    }
                    .heading{
                        color: $Black-color;
                    }
                    .desc{
                        color: $Black-color-65;
                        margin: 24px 0 40px 0;
                    }
                    .info-ul{
                        .info-li{
                            &:not(:last-child){
                                margin-bottom: 16px;
                            }
                            .info-a{
                                display: inline-flex;
                                align-items: center;
                                gap: 0 16px;
                                &:hover{
                                    .icon-otr{
                                        background-color: $Primary-color;
                                        .icon{
                                            path{
                                                fill: $White-color;
                                                opacity: 1;
                                            }
                                        }
                                    }
                                    .info-text{
                                        color: $Primary-color;
                                    }
                                }
                                .icon-otr{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 52px;
                                    height: 52px;
                                    border-radius: 100%;
                                    background-color: $Dark-color-15;
                                    transition: .3s;
                                    .icon{
                                        transition: .3s;
                                        path{
                                            transition: .3s;
                                        }
                                    }
                                }
                                .info-text{
                                    color: $Black-color;
                                    flex: 1;
                                    transition: .3s;
                                    word-break: break-all;
                                }
                            }
                        }
                    }
                    .social-otr{
                        display: flex;
                        align-items: center;
                        gap: 0 30px;
                        margin-top: 30px;
                        @include breakpoint($secreen-xxs){
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 16px 0;
                        }
                        .social-heading{
                            color: $Black-color;
                        }
                        .icon-ul{
                            display: flex;
                            align-items: center;
                            gap: 0 16px;
                            .icon-li{
                                .icon-a{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 52px;
                                    height: 52px;
                                    border-radius: 100%;
                                    background-color: $Dark-color-15;
                                    transition: .3s;
                                    &:hover{
                                        background-color: $Primary-color;
                                        .icon{
                                            path{
                                                fill: $White-color;
                                                opacity: 1;
                                            }
                                        }
                                    }
                                    .icon{
                                        transition: .3s;
                                        path{
                                            transition: .3s;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .col-form-otr{
                .col-form-inr{
                    margin: 0;
                    background-color: $White-color;
                    box-shadow: $Box-shadow;
                    padding: 36px;
                    @include breakpoint($secreen-xxs){
                        padding: 24px;
                    }
                    .input-otr{
                        &:not(:last-child){
                            margin-bottom: 30px;
                        }
                        .input{
                            width: 100%;
                        }
                        .text-area{
                            appearance: none;
                            resize: none;
                            height: 160px;
                        }
                    }
                    .action{
                        display: flex;
                        width: 100%;
                        .submit-btn{
                            width: 100%;
                            &:hover{
                                border: 1px solid $Primary-color;
                                color: $Primary-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
        Contact Section End Here
=====================================*/