

/*=====================================
    reviews Section Start Here
=====================================*/

.reviews-main{
    position: relative;
    overflow: hidden;
    padding-top: 130px;
    margin-bottom: 72px;
    @include breakpoint($secreen-max-md){
        padding-top: 72px;
    }
    @include breakpoint($secreen-xs){
        padding-top: 48px;
        margin-bottom: 48px;
    }
    .container{
        .wrapper{
            text-align: center;
            margin-bottom: 72px;
            @include breakpoint($secreen-xs){
                margin-bottom: 48px;
            }
            .heading{
                color: $Black-color;
                margin-bottom: 24px;
            }
            .desc{
                color: $Black-color-65;
            }
        }
        .row-custom{
            gap: 24px 0;
            .col-box-otr{
                &:nth-child(3){
                    @include breakpoint($secreen-max-md){
                        display: none;
                    }
                }
                .col-box-inr{
                    &:not(:last-child){
                        margin-bottom: 24px;
                    }
                    .content-otr{
                        background-color: $White-color;
                        padding: 32px 36px 26px 36px;
                        box-shadow: $Box-shadow;
                        margin-bottom: 24px;
                        @include breakpoint($secreen-xs){
                            padding: 24px 28px;
                        }
                        .star-ul{
                            display: flex;
                            align-items: center;
                            gap: 0 8px;
                            margin-bottom: 16px;
                            .star-li{
                                &:last-child{
                                    .star-icon{
                                        color: $Dark-color-15;
                                    }
                                }
                                .star-icon{
                                    font-size: 20px;
                                    color: $Primary-color;
                                }
                            }
                        }
                        .comment{
                            color: $Dark-Background;
                        }
                    }
                    .profile-otr{
                        display: flex;
                        align-items: center;
                        gap: 0 20px;
                        .img-otr{
                            .img{
                                width: 52px;
                                height: 52px;
                                border-radius: 100%;
                                object-fit: cover;
                            }
                        }
                        .content{
                            .user-name{
                                color: $Black-color-65;
                                transition: .3s;
                                &:hover{
                                    color: $Primary-color;
                                }
                            }
                            .user-post{
                                color: $Black-color-40;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
    reviews Section End Here
=====================================*/