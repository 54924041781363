html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

ul,li {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    display: block;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
    padding: 0px;
    @include breakpoint($secreen-max-sm){
        br{
            display: none;
        }
    }
}

body{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.container{
    @include breakpoint($secreen-sm){
        max-width: 100%;
        padding: 0 15px;
    }
}



.heading-h1{
    font-size: $Font-size-h1;
    line-height: $line-height-h1;
    font-family: $font-family-extra-bold;
    @include breakpoint($secreen-xs){
        font-size: 44px;
        line-height: 64px;
    }
    @include breakpoint($secreen-xxs){
        font-size: 32px;
        line-height: 54px;
    }
}
.heading-h2{
    font-size: $Font-size-h2;
    line-height: $line-height-h2;
    font-family: $font-family-bold;
    @include breakpoint($secreen-xs){
        font-size: 30px;
        line-height: 48px;
        font-family: $font-family-extra-bold;
    }
}
.heading-h3{
    font-size: $Font-size-h3;
    line-height: $line-height-h3;
    font-family: $font-family-extra-bold;
}
.heading-l{
    font-size: $Font-size-p1;
    line-height: $line-height-p1;
    font-family: $font-family-medium;
}
.heading-lb{
    font-size: $Font-size-p1;
    line-height: $line-height-p1;
    font-family: $font-family-bold;
}
.heading-m{
    font-size: $Font-size-p2;
    line-height: $line-height-p2;
    font-family: $font-family-medium;
}
.heading-mb{
    font-size: $Font-size-p2;
    line-height: $line-height-p2;
    font-family: $font-family-bold;
}
.heading-s{
    font-size: $Font-size-p3;
    line-height: $line-height-p3;
    font-family: $font-family-medium;
}
.heading-sb{
    font-size: $Font-size-p3;
    line-height: $line-height-p3;
    font-family: $font-family-bold;
}

.theme-btn{
    font-size: $Font-size-p1;
    line-height: $line-height-p1;
    font-family: $font-family-bold;
    text-align: center;
    padding: 12px 36px;
    background-color: $Primary-color;
    color: $White-color;
    border: 1px solid transparent;
    transition: .3s;
    &:hover{
        background-color: transparent;
        color: $Primary-light;
        border: 1px solid $Primary-light;
    }
}

.theme-btn2{
    font-size: $Font-size-p1;
    line-height: $line-height-p1;
    font-family: $font-family-bold;
    text-align: center;
    padding: 12px 36px;
    border: 1px solid transparent;
    background-color: $White-color;
    color: $Primary-color;
    transition: .3s;
    &:hover{
        background-color: transparent;
        color: $White-color;
        border: 1px solid $White-color;
    }
}

.theme-btn3{
    font-size: $Font-size-p1;
    line-height: $line-height-p1;
    font-family: $font-family-bold;
    text-align: center;
    padding: 12px 36px;
    background-color: $Primary-light;
    color: $Primary-color;
    transition: .3s;
    &:hover{
        background-color: $Primary-color;
        color: $White-color;
    }
}

.theme-input{
    font-size: $Font-size-p3;
    line-height: $line-height-p3;
    font-family: $font-family-medium;
    padding: 10px 24px;
    background-color: transparent;
    color: $Black-color;
    width: 100%;
    border: 1px solid $Dark-color-15;
    transition: .3s;
    &:focus{
        border: 1px solid transparent;
        background-color: $White-color;
        box-shadow: $Box-shadow;
        outline: none;
        &::placeholder{
            opacity: 0;
        }
    }
    &::placeholder{
        color: $Black-color-65;
        transition: .3s;
    }
}