@mixin site-transition {
    transition: all 0.5s ease-in-out;
    transition: 0.5s linear; /* vendorless fallback */
    -o-transition: 0.5s linear; /* opera */
    -ms-transition: 0.5s linear; /* IE 10 */
    -moz-transition: 0.5s linear; /* Firefox */
    -webkit-transition: 0.5s linear; /*safari and chrome */
}



@keyframes transition {
    0% {
        transform: translate(0) rotate(0deg);
    }
    25% {
        transform: translateY(-20px) rotate(0deg);
    }
    50% {
        transform: translateY(0) rotate(0deg);
    }
    75% {
        transform: translateY(20px) rotate(0deg);
    }
    100% {
        transform: translate(0) rotate(0deg);
    }
}


@keyframes transition2 {
    0% {
        transform: translate(0) rotate(0deg);
    }
    25% {
        transform: translateX(-10px) rotate(5deg);
    }
    50% {
        transform: translateX(0) rotate(0deg);
    }
    75% {
        transform: translateX(10px) rotate(-5deg);
    }
    100% {
        transform: translate(0) rotate(0deg);
    }
}